<script setup lang="ts">
import axios from 'axios';

const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)
const {
    customRouterState,
    navigateToPage
} = useCustomRouter()

const BTCPrice = ref('')
const oldBTCPrice = ref('')
const statusCompare = ref('')
const userGuess = ref('')
const capUserPrize = ref('')
const priceDiff = ref(0)
const getCurrentPrice = async () => {
    try {
    const response = await axios.get(`https://data-api.binance.vision/api/v3/avgPrice?symbol=BTCUSDT`);
    // const response = await axios.get(`https://api.binance.com/api/v3/avgPrice?symbol=BTCUSDT`);
    console.log(response);
    oldBTCPrice.value = BTCPrice.value
    BTCPrice.value = response?.data.price;
    return response.data.price;
  } catch (error) {
    console.error('Error fetching average price:', error);
    throw error;
  }
}

const comparePrice = () => {
 priceDiff.value = parseFloat(BTCPrice.value) - parseFloat(capUserPrize.value);
  if (priceDiff.value > 0) {
    statusCompare.value = 'moon';
  } else if (priceDiff.value < 0) {
    statusCompare.value = 'doom';
  } else {
    statusCompare.value = 'unchanged';
  }
  console.log(statusCompare.value)
  console.log(parseFloat(BTCPrice.value) , parseFloat(oldBTCPrice.value))
  console.log(priceDiff)
  showResult.value = true
}

const handleClick = async (guess: string) => {
    //show countdown 10 secs
    capUserPrize.value = BTCPrice.value
    userGuess.value = guess
    showCountdown.value = true
    await startCountdown()
    
}

const countdown = ref(10); // 10;
const showCountdown = ref(false);
const showResult = ref(false);
const startCountdown = () => {
    const intervalId = setInterval(async () => {
        countdown.value -= 1;
        if (countdown.value === 0) {
            clearInterval(intervalId);
            showCountdown.value = false;
            await getCurrentPrice()
            comparePrice()
        }
    }, 1000); // 1000ms = 1s
    countdown.value = 10;
    
}

const checkResult = () => {
    if (userGuess.value === statusCompare.value) {
        //call endpoint to add point
    }else {
        //call endpoint to deduct shells
    }
}

watch(showResult, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      showResult.value = false
    }, 3000)
  }
})

onMounted(() => { 
     getCurrentPrice()
    // setInterval(() => {
    //     getCurrentPrice()
    // },20000)
})


</script>

<template>
    <div>
        <div class="bg-black text-white">
            <div class="flex flex-col justify-between h-screen overflow-y-scroll pb-20">
                <div class="flex flex-col justify-center mt-10">
                    <div>
                        <p class="font-bold text-2xl text-center">Guess the Bitcoin Price!</p>
                    </div>
                    <div class="flex flex-row mt-3 items-center justify-center gap-1">
                        <!-- <img src="/images/dollar-coin.png" alt="" class="w-6 h-6"> -->
                        <p class="font-bold text-2xl">12345 Points</p>
                    </div>
                    

                    <div class="text-center">
                        <p> BTC Price</p>
                        <p class="animate-pulse">${{ BTCPrice.replace(/(\.\d{4})\d+/, '$1') }}</p>
                        <p>10/10 Shells</p>
                    </div>

                    <div class="text-center">
                        <p class="text-xs">Guess the bitcoin price in next 10 secs</p>
                    </div>
                    <div class="flex flex-row justify-center gap-4 w-2/3 mx-auto pt-6">
                        <button class="bg-green-500 p-2 rounded w-full outline outline-2 outline-offset-2 " @click="handleClick('moon')">MOON</button>
                        <button class="bg-red-500 p-2 rounded w-full outline outline-2 outline-offset-2 " @click="handleClick('doom')">DOOM</button>
                    </div>

                    <div v-if="showCountdown" class="text-center pt-10">
                        <p class="">countdown {{ countdown }}</p>
                        <p class="text-green-500 font-bold text-lg">Guess: {{ userGuess }}</p>
                    </div>

                    <div v-if="showResult" class="text-center bg-gray-900 bg-opacity-50 h-screen w-screen z-40">
                        <div>
                            <p class="font-bold text-3xl " :class="statusCompare === 'moon' ? 'text-green-500' : 'text-red-600'">{{ statusCompare }}</p>
                            <p class="font-semibold " :class="statusCompare === 'moon' ? 'text-green-500' : 'text-red-600'">{{ priceDiff.toFixed(5) }}</p>
                            <p class=" font-semibold">From {{ parseFloat( BTCPrice).toFixed(5) }} to {{ parseFloat(capUserPrize).toFixed(5)}}</p>                          
                        </div>
                    </div>
                    <button @click="navigateToPage('guessleaderboard')">Leaderboard</button>
                </div>
            </div>
        </div>
    </div>
</template>