import { Body } from "#build/components";
import { defineStore, skipHydrate } from "pinia";

const apiUrl = "https://2tvlibae5g.execute-api.ap-southeast-1.amazonaws.com";
const apiKey = "3b3a8314-45da-4d33-8e44-a7618eacd479";
const redotKey = "69af04e5-ff5b-4dc5-a210-f82a5cebcb35";

interface IAuthState {
  isAuthenticated: boolean;
  authString: string;
  username: string;
  firstName: string;
  tgId: number;
  point: number;
  level: number;
  energy: number;
  boosterEnergy: number;
  totalPoint: number;
  maximumEnergy: number;
  tapPower: number;
  lastTapAt: string;
  isJoinCampaign: boolean;
  campaignTotalPoint: number;
  secretCampaignJoin: boolean;
  rawWalletAddress: string;
  claimS1Rewards: boolean;
  bannerclaimS1Rewards: boolean;
  usdtBalance: number;
  isRegister: boolean;
  redotPayId: string;
  haveSendSelfie:boolean;
  haveRedotPaySS:boolean;
  accumulatedPointsMining: number;
  maxMiningPoints: number;
  accumulatedEnergy : number;
  taskStatusOverall: string;
  energyNextLvl: number;
  multiTapNextLvl: number;
  energyCostUpgrade: number;
  multiTapCostUpgrade: number;
}

const STORAGE_NAME = "auth";
const DEFAULT_STATE: IAuthState = {
  isAuthenticated: false,
  authString: "",
  username: "",
  firstName: "",
  tgId: 0,
  point: 0,
  level: 0,
  energy: 0,
  boosterEnergy: 0,
  totalPoint: 0,
  maximumEnergy: 0,
  tapPower: 0,
  lastTapAt: "",
  isJoinCampaign: false,
  campaignTotalPoint: 0,
  secretCampaignJoin: false,
  rawWalletAddress: "",
  claimS1Rewards: false,
  bannerclaimS1Rewards: false,
  usdtBalance: 0,
  isRegister: false,
  redotPayId: '',
  haveSendSelfie:false,
  haveRedotPaySS:false,
  accumulatedPointsMining: 0,
  maxMiningPoints: 0,
  accumulatedEnergy : 0,
  taskStatusOverall: "",
  energyNextLvl: 0,
  multiTapNextLvl: 0,
  energyCostUpgrade: 0,
  multiTapCostUpgrade: 0,
};

export const useAuthStore = defineStore("useAuthStore", () => {
  // States
  const authState = ref<IAuthState>(DEFAULT_STATE);

  // Methods
  const updateState = (newState: IAuthState) => {
    authState.value = newState;
    if (process.client) {
      window.localStorage.setItem(STORAGE_NAME, JSON.stringify(newState));
    }
  };

  const resetState = () => {
    authState.value = DEFAULT_STATE;
    if (process.client) {
      window.localStorage.setItem(STORAGE_NAME, JSON.stringify(DEFAULT_STATE));
    }
  };

  const signIn = async (authString: string) => {
    const parsedData = Object.fromEntries(new URLSearchParams(authString));

    const user = JSON.parse(parsedData.user);
    const response = await $fetch(
      `${apiUrl}/api/penny-whale/webapp/users/auth-validate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        body: {
          dataToValidate: authString,
        },
      }
    );
    if (response) {
      updateState({
        ...authState.value,
        isAuthenticated: true,
        authString: authString,
        username: user.username,
        firstName: user.first_name,
        tgId: user.id,
        point: response.point,
        level: response.level,
        energy: response.energy,
        boosterEnergy: response.boosterEnergy,
        totalPoint: response.totalPoint,
        maximumEnergy: response.maximumEnergy,
        tapPower: response.tapPower,
        lastTapAt: response.lastTapAt,
        isJoinCampaign: response.isJoinCampaign,
        campaignTotalPoint: response.campaignTotalPoint,
        rawWalletAddress: response.rawWalletAddress,
        claimS1Rewards: response.claimS1Rewards,
        bannerclaimS1Rewards: response.claimS1Rewards,
        usdtBalance: response.usdtBalance,
        isRegister: response.isRegister,
        redotPayId: response.redotPayId,
        haveSendSelfie:response.haveSendSelfie,
        haveRedotPaySS:response.haveRedotPaySS,
      });
    }
  };

  const campaignAutoJoin = async (
    campaigncode: string,
    refCode: string,
    authString: string
  ) => {
    const parsedData = Object.fromEntries(new URLSearchParams(authString));
    const user = JSON.parse(parsedData.user);
    const response = await $fetch(
      `${apiUrl}/api/penny-whale/webapp/users/join-secret-campaign`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        body: {
          dataToValidate: authString,
          campaignCode: campaigncode,
          referralCode: refCode,
        },
        ignoreResponseError: true,
      }
    );
    if (response?.statusCode === 400) {
      updateState({
        ...authState.value,
        secretCampaignJoin: false,
      });
      signIn(authString);
      
    }else{
      updateState({
        ...authState.value,
        secretCampaignJoin: response?.status,
      });
      signIn(authString);
      if (response?.status) {
      }
    }
  };

  const secretWhale = async (
    whalecode: string,
    refCode: string,
    authString: string
  ) => {
    const response = await $fetch(
      `${apiUrl}/api/penny-whale/webapp/users/found-secret-whale`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        body: {
          dataToValidate: authString,
          secretCode: whalecode,
          referralCode: refCode,
        },
        ignoreResponseError: true,
      }
    );
      signIn(authString);
      console.log("response are =", response);
  };

  const resetRewardClaimed = async () => {
    updateState({
      ...authState.value,
      bannerclaimS1Rewards: true,
    });
  };

  const updateHaveSendSelfie = async () => {
    updateState({
      ...authState.value,
      haveSendSelfie: true,
    });
  };
  
  const updateHaveRedotPaySS = async () => {
    updateState({
      ...authState.value,
      haveRedotPaySS: true,
    });
  };

  const updateIsRegisterRedotpay = async () => {
    updateState({
      ...authState.value,
      isRegister: true,
    });
  };
  const updateRedotPayId = async (id: string) => {
    updateState({
      ...authState.value,
      redotPayId: id,
    });
  };

  const updateAuthStatePoint = (point: number) => {
    updateState({
      ...authState.value,
      point: point,
    });
  };

  const updateMiningDetails = (point: number,maxPoints) => {
    updateState({
      ...authState.value,
    accumulatedPointsMining : point,
    maxMiningPoints : maxPoints
    });
  }

  const updateAccumulatedEnergy = (energy: number,) => {
    updateState({
      ...authState.value,
    accumulatedEnergy : energy,
    });   
  }
  
  const updateTaskStatusOverall = (status: string,) => {
    updateState({
      ...authState.value,
      taskStatusOverall : status,
    });  
  }

  const updateBoosterData = (
    newenergyNextLvl: number,
    newenergyCostUpgrade: number,
    newmultiTapNextLvl: number,
    newmultiTapCostUpgrade: number
  ) => {
    updateState({
      ...authState.value,
      energyNextLvl: newenergyNextLvl,
      multiTapNextLvl: newmultiTapNextLvl,
      energyCostUpgrade: newenergyCostUpgrade,
      multiTapCostUpgrade: newmultiTapCostUpgrade,
    });
  };

  const updatePoint = async (point: number) => {
    const response = await $fetch(
      `${apiUrl}/api/penny-whale/webapp/users/add-point`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        body: {
          dataToValidate: authState.value.authString,
          point: point,
        },
      }
    );
    // console.log(response)

    updateState({
      ...authState.value,
      point: response.point,
      energy: response.energy,
      totalPoint: response.totalPoint,
      lastTapAt: response.lastTapAt,
      campaignTotalPoint: response.campaignTotalPoint
    });
    return response;
  };

  const updateRawWalletAddress = (rawAddress: string) => {
    updateState({
      ...authState.value,
      rawWalletAddress: rawAddress,
    });
  };

  const refreshSignIn = () => {
    if (authState.value.isAuthenticated) {
      signIn(authState.value.authString);
    }
  };

  return {
    authState: skipHydrate(authState),
    signIn,
    updatePoint,
    apiKey,
    apiUrl,
    redotKey,
    refreshSignIn,
    campaignAutoJoin,
    resetRewardClaimed,
    updateRawWalletAddress,
    updateAuthStatePoint,
    updateHaveSendSelfie,
    updateHaveRedotPaySS,
    updateIsRegisterRedotpay,
    updateRedotPayId,
    updateMiningDetails,
    updateAccumulatedEnergy,
    updateTaskStatusOverall,
    updateBoosterData,
    secretWhale
  };
});
