<template>
  <div class="bg-blue-950 text-white h-screen overflow-y-auto">
    <div class="flex flex-col justify-between pb-20">
      <div class="p-6">
        <h1 class="text-3xl font-bold mb-4">PennyWhale Game Campaign Terms and Conditions</h1>
        <p class="mb-4">
          Welcome to the first-ever PennyWhale Game Campaign for Season 1! By participating in this event, you agree to
          the following terms and conditions:
        </p>

        <!-- Campaign Duration -->
        <div class="mb-6">
          <h2 class="text-2xl font-semibold mb-2">Campaign Duration</h2>
          <ul class="list-disc pl-6">
            <li>The campaign will end on 30 Sept 23:59:59 UTC.</li>
          </ul>
        </div>

        <!-- Fair Play and Coin Collection -->
        <div class="mb-6">
          <h2 class="text-2xl font-semibold mb-2">Fair Play and Coin Collection</h2>
          <ul class="list-decimal pl-6 space-y-2">
            <li><strong>Fair Play:</strong> All participants will start with an equal opportunity to collect coins from
              the moment the contest begins.</li>
            <li><strong>Coin Validity:</strong> Only coins collected during the campaign period will be considered for
              ranking and rewards.</li>
            <li><strong>Coin Earning Opportunities:</strong> You can earn coins by playing Tap Tap, refilling energy
              daily, completing daily tasks, following our social media accounts, and referring friends to join the
              game.</li>
          </ul>
        </div>

        <!-- Ranking and Leaderboard -->
        <div class="mb-6">
          <h2 class="text-2xl font-semibold mb-2">Ranking and Leaderboard</h2>
          <ul class="list-decimal pl-6 space-y-2">
            <li><strong>Leaderboard:</strong> All player rankings will be displayed on the leaderboard, with rankings
              based on campaign points.</li>
            <li><strong>Campaign Points:</strong> Campaign points are defined as the total number of coins earned during
              the campaign.</li>
            <li><strong>Top 500 Visibility:</strong> Only participants who rank in the top 500 will have their positions
              shown on the leaderboard.</li>
            <li><strong>Rewards:</strong> The more whales (players) who join the game, the more rewards will be
              distributed.</li>
          </ul>
        </div>

        <!-- Prize Pool and Reward Calculation -->
        <div class="mb-6">
          <h2 class="text-2xl font-semibold mb-2">Prize Pool and Reward Calculation</h2>
          <p class="mb-4"><strong>Prize Pool:</strong> The base prize pool is set at 200 USDT, with a maximum prize pool
            of 1000 USDT.</p>
          <ul class="list-decimal pl-6 space-y-2">
            <li><strong>Reward Growth:</strong> For every 10,000 coins earned by all players, the prize pool will
              increase by 0.02 USDT.</li>
            <li><strong>Top 300 Players Rewarded:</strong> The top 300 players will receive USDT rewards.</li>
          </ul>
        </div>

        <!-- Prize Distribution -->
        <div class="mb-6">
          <h2 class="text-2xl font-semibold mb-2">Prize Distribution</h2>
          <ul class="list-decimal pl-6 space-y-2">
            <li><strong>Prize Calculation:</strong> The final prize amounts will be calculated within 3 days after the
              campaign ends.</li>
            <li><strong>Violation Policy:</strong> Players who are found to have violated the rules will be disqualified
              and forfeit their prizes.</li>
            <li><strong>Prize Distribution Timeline:</strong> Once the prizes are confirmed, they will be distributed to
              the winners’ connected wallets within 7 days.</li>
            <li><strong>Wallet Requirement:</strong> Prizes will be sent only to the wallets connected to the players'
              accounts.</li>
          </ul>
        </div>

        <!-- Prize Breakdown -->
        <div class="mb-6">
          <h2 class="text-2xl font-semibold mb-2">Prize Breakdown for 1000 USDT Pool</h2>
          <ul class="list-disc pl-6 space-y-2">
            <li><strong>1st Prize:</strong> 150 USDT</li>
            <li><strong>2nd Prize:</strong> 100 USDT</li>
            <li><strong>3rd Prize:</strong> 50 USDT</li>
            <li><strong>4th Prize:</strong> 30 USDT</li>
            <li><strong>5th Prize:</strong> 20 USDT</li>
            <li><strong>6th-20th Prizes:</strong> 150 USDT total (10 USDT each)</li>
            <li><strong>21st-50th Prizes:</strong> 150 USDT total (5 USDT each)</li>
            <li><strong>51st-100th Prizes:</strong> 150 USDT total (3 USDT each)</li>
            <li><strong>101st-300th Prizes:</strong> 200 USDT total (1 USDT each)</li>
          </ul>
        </div>

        <!-- General Provisions -->
        <div>
          <h2 class="text-2xl font-semibold mb-2">General Provisions</h2>
          <ul class="list-disc pl-6 space-y-2">
            <li><strong>Amendments:</strong> PennyWhale reserves the right to amend these terms and conditions at any
              time.</li>
            <li><strong>Final Decisions:</strong> PennyWhale’s decisions regarding the campaign are final.</li>
          </ul>
          <p class="mt-4">By participating, you acknowledge that you understand and agree to the terms and conditions
            stated above.</p>
          <p class="mt-4 font-semibold">Good luck, and have fun!</p>
        </div>
      </div>
    </div>
  </div>
</template>
