<script setup lang="ts">
import { useWebAppNavigation } from 'vue-tg';
import useToast from '@/composables/useToast'


const {
    customRouterState,
    navigateToPage
} = useCustomRouter()

const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)
const { refreshSignIn } = authStore
const { openLink } = useWebAppNavigation()
const { addNewSuccessToast } = useToast();

const variation = {
    '460': {
        imageClass: 'w-[460px]',
        segmentAclass: 'bottom-[40px] left-[50px] tracking-[.83em] text-3xl',
        segmentBclass: 'bottom-[40px] right-[30px] tracking-[.82em] text-3xl'
    },
    '400': {
        imageClass: 'w-[400px]',
        segmentAclass: 'bottom-[33px] left-[43px] tracking-[1em] text-2xl',
        segmentBclass: 'bottom-[33px] right-[21px] tracking-[1em] text-2xl'
    },
    '340': {
        imageClass: 'w-[340px]',
        segmentAclass: 'bottom-[27px] left-[38px] tracking-[1em] text-xl',
        segmentBclass: 'bottom-[27px] right-[18px] tracking-[1em] text-xl'
    },
    '280': {
        imageClass: 'w-[280px]',
        segmentAclass: 'bottom-[20px] left-[32px] tracking-[.85em] text-lg',
        segmentBclass: 'bottom-[20px] right-[17px] tracking-[.85em] text-lg'
    },
    '220': {
        imageClass: 'w-[220px]',
        segmentAclass: 'bottom-[15px] left-[22px] tracking-[.75em] text-md',
        segmentBclass: 'bottom-[15px] right-[10px] tracking-[.75em] text-md'
    }
}


const currentVariation = ref(variation['280'])

let loop = null;
const campaignRect = ref(null);
const isTiny = ref(false);
const campaignAmountA = ref("");
const campaignAmountB = ref("");
const campaignAmount = ref(0);

const campaignPreviousAmount = ref(0);
const campaignCurrentAmount = ref(0);
const campaignPreviousTimestamp = ref(0);
const campaignCurrentTimestamp = ref(0);
// const isJoined = authState.value.isJoinCampaign;

const refreshLeaderboardCalled = ref(false);

const rearrangeJackpotAmount = () => {
    const currentTime = new Date().getTime() - 600000;
    const timeDifference = new Date(campaignCurrentTimestamp.value).getTime() - new Date(campaignPreviousTimestamp.value).getTime();
    const prizeDifference = campaignCurrentAmount.value - campaignPreviousAmount.value
    const prizePerSecond = prizeDifference / timeDifference;
    const timeElapsed = currentTime - new Date(campaignPreviousTimestamp.value).getTime();
    const currentPrize = Number(campaignPreviousAmount.value) + (prizePerSecond * timeElapsed);
    if (currentPrize > campaignCurrentAmount.value) {
        campaignAmount.value = campaignCurrentAmount.value
        refreshLeaderboardCalled.value = true;
        getPrizePool();
    } else {
        campaignAmount.value = currentPrize.toFixed(4)
    }
    //const amountString = campaignAmount.value.toFixed(4);
    const amountString = Number(campaignAmount.value).toFixed(4);
    if (!amountString || amountString === "" || amountString === "0.0000") {
        campaignAmountA.value = "0000";
        campaignAmountB.value = "0000";
        return;
    } else {
        const [beforeDecimal, afterDecimal] = amountString.split('.');
        const firstFour = beforeDecimal.padStart(4, '0'); // Pad the digits
        const nextFour = afterDecimal
        campaignAmountA.value = firstFour;
        campaignAmountB.value = nextFour;
    }
    const rect = campaignRect.value.getBoundingClientRect()
    isTiny.value = false;
    if (rect.width > 500) {
        currentVariation.value = variation['460']
    } else if (rect.width > 440) {
        currentVariation.value = variation['400']
    } else if (rect.width > 380) {
        currentVariation.value = variation['340']
    } else if (rect.width > 320) {
        currentVariation.value = variation['280']
    } else {
        currentVariation.value = variation['220']
        isTiny.value = true;
    }
}

const joinTelegram = () => {
    openLink("https://t.me/pennywhalechannel")
}

const showEnterInviteCode = ref(false);
const inviteCode = ref("");
const responseMessage = ref("");
const joinCampaign = async () => {
    const response = await fetch(`${authStore.apiUrl}/api/penny-whale/webapp/campaign/join-campaign`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': authStore.apiKey
        },
        body: JSON.stringify({
            dataToValidate: authState.value.authString,
            campaignId: inviteCode.value.toUpperCase()
        }),
    })
    const data = await response.json();
    if (data.statusCode == 200) {
        showEnterInviteCode.value = false
        addNewSuccessToast("You have join the campaign.", "Success")
    }
    if (data.statusCode == 400) {
        responseMessage.value = data?.message?.en
    }
    refreshSignIn();
    getUserRank();
    getLeaderboardRank();
}

const claimRewardS1 = async () => {
    const response = await fetch(`${authStore.apiUrl}/api/penny-whale/webapp/campaign/claim-reward`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-API-KEY': authStore.apiKey
        },
        body: JSON.stringify({
            dataToValidate: authState.value.authString,
        }),
    })
    const data = await response.json();
    if (data.statusCode == 200) {
        addNewSuccessToast("You have claimed the reward.", "Success")
        authState.value.claimS1Rewards = true
        authState.value.usdtBalance = data.usdtAmount
    }
    if (data.statusCode == 400) {
        addNewSuccessToast(data?.message?.en, "Success")
        responseMessage.value = data?.message?.en
    }
}

const prizePool = ref([]);
const getPrizePool = async () => {
    const url = new URL(`${authStore.apiUrl}/api/penny-whale/webapp/campaign/details`);
    const response = await $fetch(url.toString(),
        {
            headers: {
                "X-API-KEY": authStore.apiKey,
            },
        }
    );
    prizePool.value = response
    campaignCurrentAmount.value = prizePool?.value.prize
    campaignCurrentTimestamp.value = prizePool?.value.currentPrizeUpdatedAt
    campaignPreviousAmount.value = prizePool?.value.previousPrize
    campaignPreviousTimestamp.value = prizePool?.value.lastPrizeUpdatedAt
    refreshLeaderboardCalled.value = false;
}

const userRank = ref([]);
const getUserRank = async () => {
    const url = new URL(`${authStore.apiUrl}/api/penny-whale/webapp/campaign/current-rank-user-leaderboard`);
    const response = await $fetch(url.toString(),
        {
            headers: {
                "X-API-KEY": authStore.apiKey,
            },
            query: {
                dataToValidate: authState.value.authString,
            }
        }
    );
    userRank.value = response
}

const listLeaderboard = ref([]);
const getLeaderboardRank = async () => {
    const url = new URL(`${authStore.apiUrl}/api/penny-whale/webapp/campaign/leaderboard`);
    const response = await $fetch(url.toString(),
        {
            headers: {
                "X-API-KEY": authStore.apiKey,
            },
            query: {
                dataToValidate: authState.value.authString,
            }
        }
    );
    listLeaderboard.value = response
}



const formatNumbers = (number: number) => {
    if (number >= 1000000000) {
        return (number / 1000000).toFixed(2) + 'b';
    } else if (number >= 1000000) {
        return (number / 1000000).toFixed(2) + 'm';
    } else if (number >= 1000) {
        return (number / 1000).toFixed(2) + 'k';
    } else {
        return number?.toLocaleString();
    }
}

onMounted(() => {
    // loop = setInterval(() => {
    //     rearrangeJackpotAmount()
    // }, 1000)
    getUserRank()
    getLeaderboardRank()
    getPrizePool()
})

onUnmounted(() => {
    // clearInterval(loop)
})

const rewardCampaign = (rank: number) => {

    if (rank == 1) {
        return 150
    } else if (rank == 2) {
        return 100
    } else if (rank == 3) {
        return 50
    } else if (rank == 4) {
        return 30
    } else if (rank == 5) {
        return 20
    } else if (rank > 5 && rank <= 20) {
        return 10
    } else if (rank > 20 && rank <= 50) {
        return 5
    } else if (rank > 50 && rank <= 100) {
        return 3
    } else if (rank > 100 && rank <= 300) {
        return 1
    } else {
        return 0
    }
}

</script>
<template>
    <div>
        <div class="bg-[url('/images/mining_bg.jpg')] bg-cover bg-center h-screen z-50 pt-10 overflow-y-scroll"
            ref="campaignRect">
            <div class="mb-28">

                <div class="flex flex-col items-center justify-start md:mx-4 pt-4">
                    <p class="text-white text-center text-4xl mb-4">Congratulations to Season #1 Winners</p>
                    <div class="bg-blue-600 rounded-2xl m-4 ">
                        <div class="w-full p-4 flex flex-col">
                            <!-- <p class="text-white max-w-md text-center mx-auto py-4"> We are finalising the results. You
                                will be able to claim your USDT soon. Stay tuned on our social media for more updates
                            </p> -->
                            <p class="text-white max-w-md text-center mx-auto py-4">
                                Thank you for participating in Season #1. Stay tuned on our social media for more
                                updates.
                            </p>
                            <button v-if="userRank?.rank <= 300 && !authState.claimS1Rewards" @click="claimRewardS1()"
                                class="bg-amber-400 font-semibold py-2 px-2 rounded-xl mt-4 w-3/4 mx-auto">
                                Claim Rewards
                            </button>
                            <div class="flex items-center justify-center">
                                <p @click="navigateToPage('campaignrule')" class="text-blue-300 text-sm">Campaign rules
                                </p>
                            </div>
                            <!-- <p class="text-white max-w-md text-center mx-auto">Countdown: 168h 45m </p> -->
                        </div>
                    </div>
                    <!-- <div class="relative">
                        <p class="absolute text-white font-semibold" :class="currentVariation.segmentAclass">
                            {{ campaignAmountA }}</p>
                        <p class="absolute text-white font-semibold" :class="currentVariation.segmentBclass">
                            {{ campaignAmountB }}</p>
                        <img src="/images/jackpot-wheel.png" alt="Jackpot Wheel" class="h-auto"
                            :class="currentVariation.imageClass" />
                    </div> -->
                    <!-- <div class="w-full p-4 flex flex-col" v-if="!authState.isJoinCampaign">
                        <p class="text-white max-w-md text-center mx-auto"> Welcome to the 1st PennyWhale Season 1
                            Tournament!
                            Earn up to $1,000 USDT worth of rewards. Join our telegram to compete:
                        </p>
                        <button @click="joinTelegram()"
                            class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-2 rounded-xl mt-4 w-3/4 mx-auto">
                            Join TG to get a code
                        </button>
                        <button class="bg-blue-500 text-white font-semibold py-2 px-2 rounded-xl mt-4 w-3/4  mx-auto"
                            @click="showEnterInviteCode = true">
                            Enter Code to participate
                        </button>
                        <div class="flex items-center justify-center">
                            <p @click="navigateToPage('campaignrule')" class="text-blue-300 text-sm">Campaign rules</p>
                        </div>
                    </div> -->
                    <!-- <div v-if="authState.isJoinCampaign === true" class="w-full p-4 flex flex-col">
                        <p class="text-white max-w-md text-center mx-auto"> Welcome to the 1st PennyWhale Season 1
                            Tournament!
                            Earn up to $1,000 USDT worth of rewards.
                        </p>
                        <button class="bg-blue-500 text-white py-2 px-2 rounded-xl mt-4 w-3/4 mx-auto"
                            @click="navigateToPage('earn')">
                            Earn More Coins
                        </button>
                        <div class="flex items-center justify-center">
                            <p @click="navigateToPage('campaignrule')" class="text-blue-300 text-sm">Campaign rules</p>
                        </div>
                    </div> -->

                    <!-- <div v-if="showEnterInviteCode"
                        class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
                        <div class="bg-blue-700 rounded-lg p-4 w-3/4 max-w-lg">
                            <div class="flex flex-row-reverse items-end">
                                <button @click="showEnterInviteCode = false">
                                    <Icon name="carbon:close-filled" class="h-6 w-6" style="color: white;" />
                                </button>
                            </div>
                            <p class="text-white text-center mb-4">Enter the invite code to participate </p>
                            <input class="w-full p-2 rounded-lg bg-gray-100" type="text" v-model="inviteCode"
                                placeholder="Code">
                            <p class="text-red-500 text-sm" v-if="responseMessage">*{{ responseMessage }}</p>
                            <div class="flex flex-row-reverse items-center justify-center">
                                <button @click="joinCampaign()"
                                    class="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-xl mt-4">Submit
                                </button>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div v-if="authState.isJoinCampaign" class="w-full p-4">
                        <div
                            class="flex items-center justify-between rounded-lg py-2 my-2 px-2 w-full space-x-4 bg-blue-300">
                            <p class="flex-1 text-left font-semibold">#{{ userRank.rank >= 500 ? '5++' : userRank.rank
                                }}</p>
                            <div v-if="isTiny" class="flex-grow">                             
                                <p class="flex-grow text-left truncate">{{ authState.username }}</p>
                                <div class="flex items-center rounded-3xl px-2 py-1 bg-blue-400">
                                    <img src="/images/dollar-coin.png" alt="coin" class="w-4 h-4 mr-2">
                                    <p class="text-white font-semibold text-right">{{
                                        formatNumbers(authState.campaignTotalPoint) }}
                                    </p>
                                </div>
                            </div>
                            <p v-if="!isTiny" class="flex-grow text-left truncate">{{ authState.username }}</p>
                            <div v-if="!isTiny" class="flex items-center rounded-3xl px-2 py-1 bg-blue-400">
                                <img src="/images/dollar-coin.png" alt="coin" class="w-4 h-4 mr-2">
                                <p class="text-white font-semibold text-right">{{
                                    formatNumbers(authState.campaignTotalPoint) }}
                                </p>
                            </div>
                        </div>
                    </div> -->
                    <p class="text-2xl text-white" v-if="listLeaderboard.length > 50">Seasons 1 Winners</p>
                    <div class="w-full p-4" v-if="listLeaderboard.length > 50">
                        <div class="bg-gradient-to-b from-yellow-500 via-red-500 to-yellow-800 rounded-2xl p-2">
                            <div class="flex flex-col bg-blue-900 rounded-xl p-4 w-full">
                                <div v-for="leader in listLeaderboard" :key="leader?.rank">
                                    <div class="flex items-center justify-between rounded-lg py-2 my-2 px-2 w-full space-x-4"
                                        :class="leader?.rank === 1 ? 'bg-red-300' : leader?.rank === 2 ? 'bg-amber-300' : leader?.rank === 3 ? 'bg-teal-300' : 'bg-violet-300'">

                                        <!-- Rank number -->
                                        <img v-if="leader?.rank === 1" class="w-6 h-6" src="/images/medal_gold.png"
                                            alt="medal" />
                                        <img v-else-if="leader?.rank === 2" class="w-6 h-6"
                                            src="/images/medal_silver.png" alt="medal" />
                                        <img v-else-if="leader?.rank === 3" class="w-6 h-6"
                                            src="/images/medal_bronze.png" alt="medal" />
                                        <p v-else class="text-left"
                                            :class="isTiny ? 'text-sm' : 'text-md font-semibold'">#{{ leader?.rank }}
                                        </p>


                                        <div v-if="isTiny" class="flex-grow">
                                            <!-- Username -->
                                            <p class="flex-grow text-left truncate">{{ leader?.username }}</p>

                                            <!-- Random score with dollar icon -->
                                            <div class="flex items-center rounded-3xl px-2 py-1"
                                                :class="leader?.rank === 1 ? 'bg-red-500' : leader?.rank === 2 ? 'bg-amber-500' : leader?.rank === 3 ? 'bg-teal-500' : 'bg-violet-400'">

                                                <!-- <img src="/images/dollar-coin.png" alt="coin" class="w-4 h-4 mr-2"> -->

                                                <!-- Formatted score -->
                                                <p class="text-white text-right">{{
                                                    formatNumbers(rewardCampaign(leader?.rank)) }} </p>
                                                <img src="/images/tether-usdt-logo.png" alt=""
                                                    class="w-5 h-5 mt-1 ml-1">
                                            </div>
                                        </div>
                                        <!-- Username -->
                                        <p v-if="!isTiny" class="flex-grow text-left truncate">{{ leader?.username }}
                                        </p>

                                        <!-- Random score with dollar icon -->
                                        <div v-if="!isTiny" class="flex items-center rounded-3xl px-2 py-1"
                                            :class="leader?.rank === 1 ? 'bg-red-500' : leader?.rank === 2 ? 'bg-amber-500' : leader?.rank === 3 ? 'bg-teal-500' : 'bg-violet-400'">

                                            <!-- <img src="/images/dollar-coin.png" alt="coin" class="w-4 h-4 mr-2"> -->

                                            <!-- Formatted score -->
                                            <p class="text-white text-right">{{
                                                formatNumbers(rewardCampaign(leader?.rank)) }} </p>
                                            <img src="/images/tether-usdt-logo.png" alt="" class="w-5 h-5 mt-1 ml-1">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="flex items-center justify-center mt-4">
                    <button class="bg-blue-500 text-white text-lg py-2 px-2 rounded-xl mt-4 disabled"
                        @click="navigateToPage('earn')">
                        Click Here to redeem if you have won!
                    </button>
                </div> -->
            </div>
        </div>
    </div>

</template>