<script setup lang="ts">
const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)
const {
    customRouterState,
    navigateToPage
} = useCustomRouter()

const listLeaderboard = ref([]);
const getLeaderboardRank = async () => {
    const url = new URL(`${authStore.apiUrl}/api/penny-whale/webapp/campaign/leaderboard`);
    const response = await $fetch(url.toString(),
        {
            headers: {
                "X-API-KEY": authStore.apiKey,
            },
            query: {
                dataToValidate: authState.value.authString,
            }
        }
    );
    listLeaderboard.value = response
}

onMounted(() => {
    getLeaderboardRank();
})
</script>

<template>
    <div class="text-white">
        <p class="font-bold text-2xl text-center pt-10">Leaderboard</p>
        <div class="text-center pt-10 pb-4">
            <p class="font-bold text-2xl  ">Prizepool</p>
            <p>200.00</p>
        </div>
        <div class="text-center">
            <p @click="navigateToPage('guessbitcoin')">Leaderboard</p>
            <p>Rules</p>
        </div>
        <div v-for="item in listLeaderboard" class="flex flex-row items-center justify-between px-4 py-1 w-full">
            <div class="flex flex-row items-center gap-1 px-2 w-full">
                <p class="font-semibold">{{ item.rank }}</p>
                <p>{{ item.username }}</p>
            </div>
        </div>
    </div>
</template>